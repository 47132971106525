import axios, {AxiosResponse, AxiosRequestConfig} from 'axios'
import {getAuth, removeAuth} from "../../modules/auth/core/AuthHelpers"

const defaultHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

async function request(axiosConfig: AxiosRequestConfig, customConfig?: any) : Promise<AxiosResponse>{

  if(!axiosConfig) throw new Error("missing config");

  if(!axiosConfig.headers)
  {
    axiosConfig.headers = defaultHeaders;
  }
  else
  {
    if(!axiosConfig.headers["Content-Type"]) axiosConfig.headers["Content-Type"] = defaultHeaders['Content-Type'];
    if(!axiosConfig.headers["Accept"]) axiosConfig.headers["Accept"] = defaultHeaders['Accept'];
  }

  if(customConfig?.setAuth)
  {
    const auth = getAuth()
    if (auth && auth.accessToken) {
      axiosConfig.headers["Authorization"] = "Bearer " + auth.accessToken
    }
  }
  if (customConfig?.withCredentials) {
    axiosConfig.withCredentials = true;
  }

  try
  {
      // console.log("axios config", axiosConfig);
      let data = await instance.request(axiosConfig);

      return data;
  }
  catch(e: any)
  {
      if(e?.response?.status == 401)
      {
          removeAuth();
          window.location.href = "/forced-logout";
      }
      throw e;
  }
}


export {request}
