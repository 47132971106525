import { ErrorMessage, Field, Form, Formik } from "formik"
import { useEffect, useState } from "react"
import { Spinner } from "react-bootstrap"
import * as Yup from "yup";
import { KTCardBody } from "../../../../../_metronic/helpers/components/KTCardBody";
import { INTEGRATION_PROVIDER, INTEGRATION_STATUS, INTEGRATION_TYPE, IntegrationEmail } from "../core/_models";
import { activeCustomerIntegrationEmail, activeUserIntegrationEmail, createCustomerIntegrationEmail, createUserIntegrationEmail, getCustomerIntegrationEmailByCustomer, getUserIntegrationEmailByCustomer, undoneCustomerIntegrationEmail, undoneUserIntegrationEmail, getTokenAuthLoginGoogle, authMicrosoft, signoutMicrosoft } from "../core/_requests";
import { useGoogleLogin } from "@react-oauth/google";
import Swal from 'sweetalert2'
import { KTIcon } from "../../../../../_metronic/helpers";
const spinnerStyle = {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}

const spinnerTextStyle = {
    color: '#93a4ee',
    fontWeight: 'bold',
    padding: 50,
    margin: 50,
}
const IntegrationEmailFormModal = ({ onClose, userConfig }: any) => {
    const [loading, setLoading] = useState(false)
    const [loadingForm, setLoadingForm] = useState(false)
    const [integrationEmail, setIntegrationEmail] = useState<IntegrationEmail | null>(null);
    const [showPassword, setShowPassword] = useState(false);
    const validationSchema = Yup.object({
        host: Yup.string().required("Campo obrigatório"),
        email: Yup.string().required("Campo obrigatório"),
        securityType: Yup.string().required("Campo obrigatório"),
        port: Yup.number().typeError("Deve ser um número").required("Campo obrigatório"),
        password: Yup.string().required("Campo obrigatório"),
    });
    const getData = async () => {
        setLoading(true);
        try {
            const response: null | IntegrationEmail = userConfig ? await getUserIntegrationEmailByCustomer() : await getCustomerIntegrationEmailByCustomer();
            if (response) {
                setIntegrationEmail(response);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }

    };

    useEffect(() => {
        getData();
    }, []);

    const loginGmail = useGoogleLogin({
        flow: 'auth-code',
        scope: 'openid profile email https://www.googleapis.com/auth/gmail.modify',
        onSuccess: async (codeResponse) => {
            try {
                setLoadingForm(true)
                const response = await getTokenAuthLoginGoogle(codeResponse.code);
                if (integrationEmail?.id && integrationEmail.status == INTEGRATION_STATUS.DISABLED) {
                    let dataToSend = { ...integrationEmail }
                    const config = {
                        host: 'smtp.gmail.com',
                        port: 587,
                        emailAddress: response.email,
                        displayName: response.name,
                        securityType: 'tls',
                        password: response.access_token,
                        refreshToken: response.refresh_token
                    }
                    dataToSend.config = config
                    userConfig ? await activeUserIntegrationEmail({
                        id: dataToSend.id,
                        providerId: INTEGRATION_PROVIDER.GMAIL,
                        config: config,
                    }) : await activeCustomerIntegrationEmail({
                        id: dataToSend.id,
                        providerId: INTEGRATION_PROVIDER.GMAIL,
                        config: config,
                    });
                } else {
                    const config = {
                        host: 'smtp.gmail.com',
                        port: 587,
                        emailAddress: response.email,
                        displayName: response.name,
                        securityType: 'tls',
                        password: response.access_token,
                        refreshToken: response.refresh_token
                    }
                    userConfig ?
                        await createUserIntegrationEmail({
                            providerId: INTEGRATION_PROVIDER.GMAIL,
                            config: config,
                        })
                        : await createCustomerIntegrationEmail({
                            providerId: INTEGRATION_PROVIDER.GMAIL,
                            config: config,
                        });
                }
                Swal.fire('E-mail configurado com sucesso', '', 'success')
                await getData()
            } catch (error: any) {
                let text = 'Erro ao configurar e-mail, tente novamente mais tarde...'

                Swal.fire({
                    title: 'Opss..',
                    text: text,
                    icon: 'error',
                    confirmButtonText: 'OK',
                })
            } finally {
                setLoadingForm(false);
            }
        },
        onError: () => {
            setLoadingForm(false);
            Swal.fire({
                title: 'Opss..',
                text: 'Erro ao configurar e-mail, tente novamente mais tarde...',
                icon: 'error',
                confirmButtonText: 'OK',
            })
        }
    });

    const loginMicrosoft = async () => {
        try {
            const response = await authMicrosoft();
            const popup: any = window.open(response, 'microsoftAuth', 'width=600,height=600');
            let authHandled = false;
            const handleMessage = async (event: MessageEvent) => {
                if (event.origin !== window.location.origin) return;
                if (authHandled) return;
                authHandled = true; //evitar mandar varias req
                if (event.data.type === 'microsoft-auth-success') {
                    window.removeEventListener('message', handleMessage); 
                    if(!loadingForm) await createRegisterMicrosoft(event.data.nome, event.data.userName, event.data.accessToken, event.data.expiresOn, event.data.refreshToken);
                    popup.close();
                } else {
                    window.removeEventListener('message', handleMessage); 
                    console.error('Erro:', event.data.error);
                    popup.close();
                }
            };
    
            window.addEventListener('message', handleMessage);
        } catch (error) {
            console.error("Erro durante o login:", error);
        }
    };
    

    const createRegisterMicrosoft = async (name: string, userName: string, accessToken: string, expiresOn:string, refreshToken: string) => {
        try {
            setLoadingForm(true)
            if (integrationEmail?.id && integrationEmail.status == INTEGRATION_STATUS.DISABLED) {
                let dataToSend = { ...integrationEmail }
                const config = {
                    host: 'smtp.office365.com',
                    port: 587,
                    emailAddress: userName,
                    displayName: name,
                    securityType: 'tls',
                    password: accessToken,
                    refreshToken:refreshToken,
                    expiresOn:expiresOn
                }
                dataToSend.config = config
                userConfig ? await activeUserIntegrationEmail({
                    id: dataToSend.id,
                    providerId: INTEGRATION_PROVIDER.MICROSOFT,
                    config: config,
                }) : await activeCustomerIntegrationEmail({
                    id: dataToSend.id,
                    providerId: INTEGRATION_PROVIDER.MICROSOFT,
                    config: config,
                });
            } else {
                const config = {
                    host: 'smtp.office365.com',
                    port: 587,
                    emailAddress: userName,
                    displayName: name,
                    securityType: 'tls',
                    password: accessToken,
                    refreshToken:refreshToken,
                    expiresOn:expiresOn
                }
                userConfig ?
                    await createUserIntegrationEmail({
                        providerId: INTEGRATION_PROVIDER.MICROSOFT,
                        config: config,
                    })
                    : await createCustomerIntegrationEmail({
                        providerId: INTEGRATION_PROVIDER.MICROSOFT,
                        config: config,
                    });
            }
            Swal.fire('E-mail configurado com sucesso', '', 'success')
            await getData()
        } catch (error: any) {
            let text = 'Erro ao configurar e-mail, tente novamente mais tarde...'

            Swal.fire({
                title: 'Opss..',
                text: text,
                icon: 'error',
                confirmButtonText: 'OK',
            })
        } finally {
            setLoadingForm(false);
        }
    }

    const handleSubmit = async (values: any, { setSubmitting }: any) => {
        setLoadingForm(true);
        try {
            if (integrationEmail?.id && integrationEmail.status == INTEGRATION_STATUS.DISABLED) {
                let dataToSend = { ...integrationEmail }
                const config = {
                    host: values.host,
                    port: values.port,
                    emailAddress: values.email,
                    displayName: values.displayName,
                    securityType: values.securityType,
                    password: values.password,
                }
                dataToSend.config = config
                userConfig ? await activeUserIntegrationEmail({
                    id: dataToSend.id,
                    providerId: INTEGRATION_PROVIDER.SMTP,
                    config: config,
                }) : await activeCustomerIntegrationEmail({
                    id: dataToSend.id,
                    providerId: INTEGRATION_PROVIDER.SMTP,
                    config: config,
                });
            } else {
                const config = {
                    host: values.host,
                    port: values.port,
                    emailAddress: values.email,
                    displayName: values.displayName,
                    securityType: values.securityType,
                    password: values.password,
                };
                userConfig ?
                    await createUserIntegrationEmail({
                        providerId: INTEGRATION_PROVIDER.SMTP,
                        config: config,
                    })
                    : await createCustomerIntegrationEmail({
                        providerId: INTEGRATION_PROVIDER.SMTP,
                        config: config,
                    });
            }
            Swal.fire('E-mail configurado com sucesso', '', 'success')
            await getData()
        } catch (error: any) {
            let text = 'Erro ao configurar e-mail, tente novamente mais tarde...'
            if (error?.response?.data?.message) {
                text = error?.response?.data?.message
            }
            Swal.fire({
                title: 'Opss..',
                text: text,
                icon: 'error',
                confirmButtonText: 'OK',
            })
        } finally {
            setLoadingForm(false);
            setSubmitting(false);
        }
    };

    const confirmUndone = () => {
        Swal.fire({
            title: 'Desativar Email',
            text: 'Tem certeza que deseja desativar o e-mail?',
            showDenyButton: true,
            confirmButtonText: 'Sim',
            denyButtonText: `Não`,
        }).then((result) => {
            if (result.isConfirmed) {
                handleUndone()
            } else if (result.isDenied) {
                //Swal.fire('Changes are not saved', '', 'info')
            }
        })
    }

    const handleUndone = async () => {
        if (!integrationEmail) return
        setLoadingForm(true);
        try {
            userConfig ? await undoneUserIntegrationEmail(integrationEmail?.id) : await undoneCustomerIntegrationEmail(integrationEmail?.id);
            setIntegrationEmail(null)
            Swal.fire('E-mail desativado com sucesso', '', 'success')
            await getData()
        } catch (error: any) {
            let text = 'Erro ao desativar e-mail, tente novamente mais tarde...'
            Swal.fire({
                title: 'Opss..',
                text: text,
                icon: 'error',
                confirmButtonText: 'OK',
            })
        } finally {
            setLoadingForm(false);
        }
    };

    return (
        loading ? (
            <div style={spinnerStyle}>
                <Spinner style={spinnerTextStyle} />
            </div>
        ) : (
            integrationEmail && integrationEmail.status == INTEGRATION_STATUS.ACTIVATED && integrationEmail.integration.providerId == INTEGRATION_PROVIDER.GMAIL ? (
                <KTCardBody className='p-4'>
                    <div style={{ textAlign: 'center', padding: '30px', border: '1px solid #e0e0e0', borderRadius: '12px', backgroundColor: '#fafafa', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
                        <h4 style={{ fontWeight: 'lighter', marginBottom: '20px', color: '#333' }}>Você está logado com o Gmail</h4>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "15px", marginBottom: "25px" }}>
                            <i className="fab fa-google" style={{ fontSize: "28px", color: "#DB4437" }}></i>
                            <div style={{ textAlign: "left" }}>
                                <strong style={{ fontSize: "16px", color: "#555" }}>{integrationEmail?.config?.displayName}</strong><br />
                                <span style={{ color: "#777" }}>{integrationEmail?.config?.emailAddress}</span>
                            </div>
                        </div>
                        <button
                            type="button"
                            style={{
                                padding: "12px 25px",
                                borderRadius: '10px',
                                backgroundColor: '#FF4B4B',
                                color: '#FFF',
                                border: "none",
                                fontWeight: 'bold',
                                cursor: "pointer",
                                boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                                transition: 'background-color 0.3s ease'
                            }}
                            className='btn btn-sm fw-bold'
                            onClick={confirmUndone}
                            disabled={loading || loadingForm}
                            onMouseOver={(e: any) => e.target.style.backgroundColor = '#E03E3E'}
                            onMouseOut={(e: any) => e.target.style.backgroundColor = '#FF4B4B'}
                        >
                            {loadingForm ? <Spinner size="sm" /> : "Desativar"}
                        </button>
                    </div>
                </KTCardBody>
            ) : (
                integrationEmail && integrationEmail.status == INTEGRATION_STATUS.ACTIVATED && integrationEmail.integration.providerId == INTEGRATION_PROVIDER.MICROSOFT ? (
                    <KTCardBody className='p-4'>
                        <div style={{ textAlign: 'center', padding: '30px', border: '1px solid #e0e0e0', borderRadius: '12px', backgroundColor: '#fafafa', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
                            <h4 style={{ fontWeight: 'lighter', marginBottom: '20px', color: '#333' }}>Você está logado com a Microsoft</h4>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "15px", marginBottom: "25px" }}>
                                <i
                                    className="fab fa-microsoft"
                                    style={{ fontSize: "28px", color: "#74C0FC" }}
                                ></i>
                                <div style={{ textAlign: "left" }}>
                                    <strong style={{ fontSize: "16px", color: "#555" }}>{integrationEmail?.config?.displayName}</strong><br />
                                    <span style={{ color: "#777" }}>{integrationEmail?.config?.emailAddress}</span>
                                </div>
                            </div>
                            <button
                                type="button"
                                style={{
                                    padding: "12px 25px",
                                    borderRadius: '10px',
                                    backgroundColor: '#FF4B4B',
                                    color: '#FFF',
                                    border: "none",
                                    fontWeight: 'bold',
                                    cursor: "pointer",
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                                    transition: 'background-color 0.3s ease'
                                }}
                                className='btn btn-sm fw-bold'
                                onClick={confirmUndone}
                                disabled={loading || loadingForm}
                                onMouseOver={(e: any) => e.target.style.backgroundColor = '#E03E3E'}
                                onMouseOut={(e: any) => e.target.style.backgroundColor = '#FF4B4B'}
                            >
                                {loadingForm ? <Spinner size="sm" /> : "Desativar"}
                            </button>
                        </div>
                    </KTCardBody>)
                    :
                    <>
                        <KTCardBody className='p-4'>
                            <h4 style={{ fontWeight: 'lighter' }}>Configuração manual</h4>
                            <Formik enableReinitialize initialValues={{
                                id: integrationEmail?.id || "",
                                host: integrationEmail?.config?.host || "",
                                port: integrationEmail?.config?.port?.toString() || "",
                                email: integrationEmail?.config?.emailAddress || "",
                                displayName: integrationEmail?.config?.displayName || "",
                                securityType: integrationEmail?.config?.securityType || "",
                                password: integrationEmail?.config?.password || "",
                            }} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                {({ isSubmitting }) => (
                                    <Form style={{ display: "flex", flexDirection: "column", gap: "15px" }}>

                                        <div style={{ display: "flex", gap: "15px" }}>
                                            <div style={{ flex: 1 }}>
                                                <Field disabled={integrationEmail?.status == INTEGRATION_STATUS.ACTIVATED || isSubmitting || loading || loadingForm} type="text" name="host" className="form-control" placeholder="Host*" />
                                                <ErrorMessage name="host" component="div" className="text-danger" />
                                            </div>
                                            <div style={{ flex: 1 }}>
                                                <Field disabled={integrationEmail?.status == INTEGRATION_STATUS.ACTIVATED || isSubmitting || loading || loadingForm} type="text" name="port" className="form-control" placeholder="Porta*" />
                                                <ErrorMessage name="port" component="div" className="text-danger" />
                                            </div>
                                        </div>

                                        <div style={{ display: "flex", gap: "15px" }}>
                                            <div style={{ flex: 1 }}>
                                                <Field disabled={integrationEmail?.status == INTEGRATION_STATUS.ACTIVATED || isSubmitting || loading || loadingForm} type="text" name="email" className="form-control" placeholder="Endereço de e-mail*" />
                                                <ErrorMessage name="email" component="div" className="text-danger" />
                                            </div>
                                            <div style={{ flex: 1 }}>
                                                <div style={{ position: 'relative' }}>
                                                    <Field
                                                        disabled={integrationEmail?.status === INTEGRATION_STATUS.ACTIVATED || isSubmitting || loading || loadingForm}
                                                        type={showPassword ? 'text' : 'password'}
                                                        name="password"
                                                        className="form-control"
                                                        placeholder="Senha*"
                                                    />
                                                    <div
                                                        onClick={() => setShowPassword((prev) => !prev)}
                                                        style={{
                                                            position: 'absolute',
                                                            top: '50%',
                                                            right: '10px',
                                                            transform: 'translateY(-50%)',
                                                            cursor: 'pointer',
                                                            zIndex: 2
                                                        }}
                                                    >
                                                        {showPassword ? (
                                                            <KTIcon iconName="eye" className="fs-1" />
                                                        ) : (
                                                            <KTIcon iconName="eye-slash" className="fs-1" />
                                                        )}
                                                    </div>
                                                </div>
                                                <ErrorMessage
                                                    name="password"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                            </div>

                                        </div>

                                        <div style={{ display: "flex", gap: "15px" }}>
                                            <div style={{ flex: 1 }}>
                                                <Field disabled={integrationEmail?.status == INTEGRATION_STATUS.ACTIVATED || isSubmitting || loading || loadingForm} as="select" name="securityType" className="form-control">
                                                    <option value="">Tipo de segurança*</option>
                                                    <option value="tls">TLS</option>
                                                    <option value="ssl">SSL</option>
                                                </Field>
                                                <ErrorMessage name="securityType" component="div" className="text-danger" />
                                            </div>
                                            <div style={{ flex: 1 }}>
                                                <Field disabled={integrationEmail?.status == INTEGRATION_STATUS.ACTIVATED || isSubmitting || loading || loadingForm} type="text" name="displayName" className="form-control" placeholder="Nome de exibição*" />
                                                <ErrorMessage name="displayName" component="div" className="text-danger" />
                                            </div>
                                        </div>

                                        <div style={{ justifyContent: 'center', alignItems: "center", display: 'flex' }}>
                                            {integrationEmail?.status == INTEGRATION_STATUS.ACTIVATED ? (
                                                <button
                                                    type="button"
                                                    style={{
                                                        padding: "10px",
                                                        width: '20%',
                                                        textAlign: "center",
                                                        borderRadius: '8px',
                                                        backgroundColor: '#FF0000',
                                                        color: '#FFFF'
                                                    }}
                                                    className='btn btn-sm fw-bold'
                                                    onClick={confirmUndone}
                                                    disabled={isSubmitting || loading || loadingForm}
                                                >
                                                    {loadingForm ? <Spinner size="sm" /> : "Desativar"}
                                                </button>
                                            ) :
                                                (
                                                    <button
                                                        type="submit"
                                                        style={{
                                                            padding: "10px",
                                                            width: '20%',
                                                            textAlign: "center",
                                                            borderRadius: '8px'
                                                        }}
                                                        className='btn btn-sm fw-bold btn-wl-custom-primary-collor'
                                                        disabled={isSubmitting || loading || loadingForm}
                                                    >
                                                        {loadingForm ? <Spinner size="sm" /> : integrationEmail?.status == INTEGRATION_STATUS.DISABLED ? "Ativar" : "Salvar"}
                                                    </button>
                                                )}
                                        </div>
                                        {integrationEmail?.status == INTEGRATION_STATUS.DISABLED || !integrationEmail ? (
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                    marginTop: "10px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "10px",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        width: "100%",
                                                        maxWidth: "300px",
                                                    }}
                                                >
                                                    <hr
                                                        style={{
                                                            flex: 1,
                                                            border: "none",
                                                            borderTop: "1px solid #575757",
                                                        }}
                                                    />
                                                    <span style={{ margin: "0 10px", color: "#666", fontSize: "18px" }}>
                                                        Ou
                                                    </span>
                                                    <hr
                                                        style={{
                                                            flex: 1,
                                                            border: "none",
                                                            borderTop: "1px solid #575757",
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        gap: "10px",
                                                        width: "100%",
                                                        maxWidth: "600px",
                                                    }}
                                                >
                                                    <button
                                                        onClick={() => loginGmail()}
                                                        type="button"
                                                        style={{
                                                            flex: 1,
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            gap: "10px",
                                                            padding: "10px",
                                                            border: "1px solid #ddd",
                                                            borderRadius: "8px",
                                                            backgroundColor: "#fff",
                                                            color: "#000",
                                                            fontWeight: "bold",
                                                            cursor: "pointer",
                                                            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                                                        }}
                                                        disabled={isSubmitting || loading || loadingForm}
                                                    >
                                                        <i
                                                            className="fab fa-google"
                                                            style={{ fontSize: "20px", color: "#DB4437" }}
                                                        ></i>
                                                        Autenticar com Google
                                                    </button>
                                                    <button
                                                        onClick={loginMicrosoft}
                                                        type="button"
                                                        style={{
                                                            flex: 1,
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            gap: "10px",
                                                            padding: "10px",
                                                            border: "1px solid #ddd",
                                                            borderRadius: "8px",
                                                            backgroundColor: "#fff",
                                                            color: "#000",
                                                            fontWeight: "bold",
                                                            cursor: "pointer",
                                                            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                                                        }}
                                                        disabled={isSubmitting || loading || loadingForm}
                                                    >
                                                        <i
                                                            className="fab fa-microsoft"
                                                            style={{ fontSize: "20px", color: "#74C0FC" }}
                                                        ></i>
                                                        Autenticar com Microsoft
                                                    </button>
                                                </div>

                                            </div>
                                        ) : (
                                            <></>
                                        )}

                                    </Form>
                                )}
                            </Formik>
                        </KTCardBody >

                    </>
            )

        )


    )
}

export { IntegrationEmailFormModal }