import { AxiosResponse } from "axios";
import { request } from "../../../../services/crm-api/request"

const createCustomerIntegrationEmail = async (dataForm: {
  providerId: number,
  config: {
    host: string;
    port: number;
    emailAddress: string;
    displayName?: string;
    securityType: string;
    password: string;
    refreshToken?: string;
    expiresOn?:string;
  }
}): Promise<any> => {
  return await request(
    {
      method: 'post',
      url: `/customer-accounts-integration-email`,
      data: dataForm,
    },
    {
      setAuth: true,
    }
  ).then((response: any) => response.data)
}

const getCustomerIntegrationEmailByCustomer = async (): Promise<any> => {

  return request({
    method: "GET",
    url: `/customer-accounts-integration-email`
  }, {
    setAuth: true
  })
    .then((d: AxiosResponse<any>) => d.data);
}

const undoneCustomerIntegrationEmail = async (id: any): Promise<any> => {
  return request(
    {
      method: 'patch',
      url: `/customer-accounts-integration-email/${id}`,
    },
    {
      setAuth: true,
    }
  )
    .then((d: AxiosResponse<any>) => d.data);
}

const activeCustomerIntegrationEmail = async (dataForm: {
  id: number,
  providerId: number,
  config: {
    host: string;
    port: number;
    emailAddress: string;
    displayName?: string;
    securityType: string;
    password: string;
    refreshToken?: string
  }
}): Promise<any> => {
  return await request(
    {
      method: 'patch',
      url: `/customer-accounts-integration-email`,
      data: dataForm,
    },
    {
      setAuth: true,
    }
  ).then((response: any) => response.data)
}

const createUserIntegrationEmail = async (dataForm: {
  providerId: number,
  config: {
    host: string;
    port: number;
    emailAddress: string;
    displayName?: string;
    securityType: string;
    password: string;
    refreshToken?: string
  }
}): Promise<any> => {
  return await request(
    {
      method: 'post',
      url: `/user-integration-email`,
      data: dataForm,
    },
    {
      setAuth: true,
    }
  ).then((response: any) => response.data)
}

const getUserIntegrationEmailByCustomer = async (): Promise<any> => {

  return request({
    method: "GET",
    url: `/user-integration-email`
  }, {
    setAuth: true
  })
    .then((d: AxiosResponse<any>) => d.data);
}

const undoneUserIntegrationEmail = async (id: any): Promise<any> => {
  return request(
    {
      method: 'patch',
      url: `/user-integration-email/${id}`,
    },
    {
      setAuth: true,
    }
  )
    .then((d: AxiosResponse<any>) => d.data);
}

const activeUserIntegrationEmail = async (dataForm: {
  id: number,
  providerId: number,
  config: {
    host: string;
    port: number;
    emailAddress: string;
    displayName?: string;
    securityType: string;
    password: string;
    refreshToken?: string;
    expiresOn?:string;
  }
}): Promise<any> => {
  return await request(
    {
      method: 'patch',
      url: `/user-integration-email`,
      data: dataForm,
    },
    {
      setAuth: true,
    }
  ).then((response: any) => response.data)
}
const getTokenAuthLoginGoogle = async (code:string): Promise<any> => {
  return await request(
    {
      method: 'GET',
      url: `/email-gmail-config?code=${code}`
    },
    {
      setAuth: true,
    }
  ).then((response: any) => response.data)
}

const authMicrosoft = async (): Promise<any> => {
  return await request(
    {
      method: 'GET',
      url: `/auth-microsoft-config/signin`
    },
    {
      setAuth: true,
      withCredentials:true
    }
  ).then((response: any) => response.data)
}

const signoutMicrosoft = async (): Promise<any> => {
  return await request(
    {
      method: 'GET',
      url: `/auth-microsoft-config/signout`
    },
    {
      setAuth: true,
      withCredentials:true
    }
  ).then((response: any) => response.data)
}
export {
  createCustomerIntegrationEmail,
  getCustomerIntegrationEmailByCustomer,
  undoneCustomerIntegrationEmail,
  activeCustomerIntegrationEmail,
  createUserIntegrationEmail,
  activeUserIntegrationEmail,
  undoneUserIntegrationEmail,
  getUserIntegrationEmailByCustomer,
  getTokenAuthLoginGoogle,
  authMicrosoft,
  signoutMicrosoft
}
