import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const MicrosoftAuthRedirect = () => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const nome = searchParams.get('nome');
        const userName = searchParams.get('userName');
        const accessToken = searchParams.get('accessToken');
        const expiresOn = searchParams.get('expiresOn');
        const refreshToken = searchParams.get('refreshToken');
        if (nome && userName && accessToken) {
            window.opener.postMessage({
                type: 'microsoft-auth-success',
                nome,
                userName,
                accessToken,
                expiresOn,
                refreshToken
            }, window.location.origin);
        } else {
            window.opener.postMessage({
                type: 'microsoft-auth-error',
                error: 'Dados de autenticação ausentes'
            }, window.location.origin);
        }

        setTimeout(() => window.close(), 1000);
    }, [searchParams]);

    return <div>Finalizando autenticação...</div>;
};
