/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { IntegrationHeader } from './components/header/IntegrationHeader'
import { IntegrationsCard } from './card/IntegrationsCard'
import { IntegrationEmailFormModalProvider } from './core/IntegrationEmailFormModalContext'
import { IntegrationEmailModal } from './manage-modal/IntegrationEmailModal'
import { GoogleOAuthProvider } from '@react-oauth/google'

interface IntegrationProps {
    userConfig?: boolean;
  }
const Integration: FC<IntegrationProps> = ({ userConfig }) => {
    return (
        <>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID_GOOGLE as string}>
            <IntegrationEmailFormModalProvider>
                <IntegrationHeader userConfig = {userConfig?true:false} />
                <IntegrationsCard />
                <IntegrationEmailModal userConfig = {userConfig?true:false}/>
            </IntegrationEmailFormModalProvider>
        </GoogleOAuthProvider>
        </>
    )
}

export { Integration }
